<template>
  <div class="col-lg-4 mb-lg-2">
    <b-button
      v-if="checkPermission('create misi')"
      v-b-modal.form-missions-modal
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Tambah Misi"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Misi
    </b-button>

    <b-modal
      id="form-missions-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Misi"
    >
      <div class="form">
        <h5>Misi</h5>
        <div class="row">

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nama :</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadMissions.name"
                  :state="validationsMissions.name ? false:null"
                  placeholder="Nama"
                />
                <small
                  v-for="(validation, index) in validationsMissions.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Tanggal Mulai :</label>
              <validation-provider>
                <flat-pickr
                  v-model="formPayloadMissions.start_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <small
                  v-for="(validation, index) in validationsMissions.start_date"
                  :key="`errorstart_date${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Tanggal Akhir :</label>
              <validation-provider>
                <flat-pickr
                  v-model="formPayloadMissions.end_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d'}"
                />
                <small
                  v-for="(validation, index) in validationsMissions.end_date"
                  :key="`errorend_date${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Minimum Total Transaksi:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPayloadMissions.minimum_transaction_total"
                    placeholder="Minimum Total transaksi"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsMissions.minimum_transaction_total"
                  :key="`errorminimum_transaction_total${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Minimum Banyak Transaksi:</label>
              <validation-provider>
                <b-input-group
                  append="X"
                >
                  <money
                    v-model="formPayloadMissions.minimum_transaction_time"
                    placeholder="Minimum Banyak transaksi"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsMissions.minimum_transaction_time"
                  :key="`errorminimum_transaction_time${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Harus Talenta Jagoan: </label><br>
              <validation-provider>
                <b-form-checkbox
                  v-model="formPayloadMissions.must_order_with_talenta_jagoan"
                  :checked="`${formPayloadMissions.must_order_with_talenta_jagoan}`"
                  name="check-button"
                  :state="validationsMissions.must_order_with_talenta_jagoan ? false:null"
                  inline
                >
                  Harus Talenta Jagoan
                </b-form-checkbox><br>
                <small
                  v-for="(validation, index) in validationsMissions.must_order_with_talenta_jagoan"
                  :key="`errorStatus${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Reward :</label>
              <validation-provider>
                <b-form-select
                  v-model="formPayloadMissions.coupon_reward_uuid"
                  :options="couponsMission"
                />
                <small
                  v-for="(validation, index) in validationsMissions.coupon_reward_uuid"
                  :key="`errorcoupon_reward_uuid${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingMissions">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingMissions"
          @click="submitItem(), editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BFormCheckbox, BFormSelect, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'

import { Money } from 'v-money'
import moment from 'moment'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BInputGroup,
    Money,
    flatPickr,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    imageCoupon: {
      type: String,
    },
    cleanUpForm: {
      type: Function,
    },
    coupons: {
      type: '',
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')
    return {
      isLoadingMissions: false,
      formPayloadMissions: {
        name: '',
        start_date: startOfMonth,
        end_date: endOfMonth,
        minimum_transaction_total: 0,
        minimum_transaction_time: 0,
        coupon_reward_uuid: '',
        must_order_with_talenta_jagoan: 1,
      },
      validationsMissions: '',
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      couponsMission: [],
    }
  },
  watch: {
    formPayload: {
      handler(value) {
        this.formPayloadMissions = value
      },
      deep: true,
    },
    coupons(value) {
      this.couponsMission = value
    },
    isLoading(value) {
      this.isLoadingMissions = value
    },
    validations(value) {
      this.validationsMissions = value
    },
    formPayloadMissions: {
      handler(value) {
        this.$emit('payloadCoupon', value)
      },
      deep: true,
    },
  },
  methods: {
    submitItem() {
      // eslint-disable-next-line eqeqeq
      if (this.formPayloadMissions.must_order_with_talenta_jagoan == true) {
        this.formPayloadMissions.must_order_with_talenta_jagoan = '1'
      } else {
        this.formPayloadMissions.must_order_with_talenta_jagoan = '0'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
  <b-card-code
    no-body
    title="Daftar Misi"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-md-12">
          <FormMissions
            :is-loading="isLoading"
            :edit-id="editId"
            :validations="validations"
            :create-item="createItem"
            :update-item="updateItem"
            :clean-up-form="cleanUpForm"
            :form-payload="formPayload"
            :coupons="coupons"
            @payloadCoupon="getPayload($event)"
          />
        </div>
      </div>
    </div>
    <ListMissions
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
      :edit-item="editItem"
      :delete-item="deleteItem"
      :detail-item="detailItem"
    />
  </b-card-code>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import moment from 'moment'

import ListMissions from '@/components/Missions/ListMissions.vue'
import FormMissions from '@/components/Missions/FormMissions.vue'

export default {
  title() {
    return 'Daftar Misi'
  },
  components: {
    BCardCode,
    ListMissions,
    FormMissions,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      editId: null,
      isLoading: true,
      result: {},
      detail: {},
      validations: {},
      formPayload: Object,
      coupons: Array,
    }
  },
  created() {
    this.getData()
    this.getCoupon()
  },
  methods: {
    getPayload(value) {
      if (value) {
        this.formPayload = value
      }
    },
    getCoupon() {
      this.$http.get('/admin/coupons', {
        params: {
          purpose: 'MISSION_REWARD',
        },
      })
        .then(response => {
          const result = response.data.data.data
          for (let i = 0; i < result.length; i++) {
            result[i].value = result[i].uuid
            result[i].text = result[i].purpose_label
            delete result[i].key
            delete result[i].label
          }
          this.coupons = result
          this.coupons.unshift({ value: '', text: 'Pilih Kupon' })
        })
    },
    cleanUpForm() {
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')

      this.editId = null
      this.formPayload = {
        name: '',
        start_date: startOfMonth,
        end_date: endOfMonth,
        minimum_transaction_total: 0,
        minimum_transaction_time: 0,
        coupon_reward_uuid: '',
        must_order_with_talenta_jagoan: false,
      }
      this.validations = ''
    },
    detailItem(item) {
      this.detail = item
      this.$bvModal.show('form-detail-modal')
    },
    createItem() {
      this.getPayload()
      const form = this.preparePayload()
      this.isLoading = true

      this.$http.post('/admin/missions', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(() => {
          this.$bvModal.hide('form-missions-modal')
          this.getData()
          successNotification(this, 'Success', 'Misi berhasil di buat')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$http.post(`/admin/missions/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(() => {
          this.$bvModal.hide('form-missions-modal')
          this.getData()
          successNotification(this, 'Success', 'Misi berhasil di ubah!')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.formPayload = {
        name: item.name,
        start_date: item.start_date,
        end_date: item.end_date,
        minimum_transaction_total: item.minimum_transaction_total,
        minimum_transaction_time: item.minimum_transaction_time,
        coupon_reward_uuid: item.coupon_reward.uuid,
        must_order_with_talenta_jagoan: item.must_order_with_talenta_jagoan,
      }
      this.$bvModal.show('form-missions-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this misi?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/missions/${id}`)
            .then(() => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Misi berhasil di hapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getData(page = 1) {
      this.isLoading = true

      this.$http.get('/admin/missions', {
        params: {
          page,
        },
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
.table > tbody > tr > td {
  max-width: 300px;
}
</style>
